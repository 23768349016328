import { CopyIcon } from '@radix-ui/react-icons';
import { Button, buttonVariants } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { useHighlight, codeThemes } from '../use-highlight';
import { exampleFullResponseString, exampleParamsUsed } from './example-full-response-string';
import { useCopyToClipboard } from '@/lib/hooks/use-copy-to-clipboard';
import { toast } from '@/components/ui/use-toast';

export const ExampleResponse = () => {
  const { highlighter, defaultColor } = useHighlight();
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 });

  const onCopy = () => {
    if (isCopied || !exampleFullResponseString) return;
    copyToClipboard(exampleFullResponseString);
    toast({
      title: 'Copied response to clipboard',
    });
  };

  const onCopyParams = () => {
    if (isCopied || !exampleParamsUsed) return;
    copyToClipboard(exampleParamsUsed);
    toast({
      title: 'Copied params to clipboard',
    });
  };

  const codeConfs = {
    lang: 'json',
    themes: codeThemes,
    defaultColor,
  };

  const codeText =
    highlighter?.codeToHtml(exampleFullResponseString, codeConfs) || exampleFullResponseString;
  const paramsText = highlighter?.codeToHtml(exampleParamsUsed, codeConfs) || exampleParamsUsed;

  return (
    <div className="flex gap-3 items-center">
      <a
        href={'/credits/new?free-trial=1'}
        className={`flex-1 text-xs md:flex-none font-mono uppercase py-6.5 ${buttonVariants({
          variant: 'ghost',
          size: 'sm',
        })} px-0`}
      >
        Free Trial
      </a>
      <div>
        <Dialog modal>
          <DialogTrigger asChild>
            <Button variant="green" className="font-mono uppercase" size={'sm'}>
              Example Response
            </Button>
          </DialogTrigger>
          <DialogContent className="max-w-screen md:max-w-3xl max-h-[84vh] overflow-auto">
            <DialogHeader>
              <DialogTitle>Example Response</DialogTitle>
              <DialogDescription>
                This is an API example response for the crawl endpoint with the metadata parameter
                enabled.
              </DialogDescription>
            </DialogHeader>
            <div className={`max-w-[100vw] overflow-hidden`}>
              <div className={`flex items-center gap-x-2 overflow-auto relative`}>
                <Button
                  type="submit"
                  variant="outline"
                  size="sm"
                  className="px-3 fixed top-20 right-12 hidden md:block"
                  onClick={onCopy}
                >
                  <span className="sr-only">Copy</span>
                  <CopyIcon className="h-4 w-4" />
                </Button>
                <div
                  dangerouslySetInnerHTML={{ __html: codeText }}
                  className="max-h-[50vh] text-sm"
                />
              </div>

              <div className="text-sm space-y-1 py-2">
                <div className="text-muted-foreground">The params used for the request.</div>
                <div className="relative">
                  <code
                    dangerouslySetInnerHTML={{ __html: paramsText }}
                    className="max-h-[20vh] text-sm"
                  />
                  <Button
                    type="submit"
                    variant="outline"
                    size="sm"
                    className="px-3 absolute top-2 right-2"
                    onClick={onCopyParams}
                  >
                    <span className="sr-only">Copy</span>
                    <CopyIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
